import * as t from "io-ts";

import { httpClient } from "@/lib/http";
import { cast } from "@/lib/typing";

export async function fetchPermissions(): Promise<string[]> {
  const response = await httpClient.get(`/api/permissions/`);
  const rawData = await response.json();
  return cast(t.array(t.string), rawData);
}
