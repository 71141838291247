import { ref, Ref } from "vue";

import { fetchPermissions } from "@/api/permissions/list";
import {
  BasePermission,
  BasePermissionType,
  InAppsPermission,
  InAppsPermissionType,
  Permission,
  PushMessagesPermission,
  PushMessagesPermissionType,
} from "@/api/permissions/typedefs";
import { getShortProductLineName, ProductLine } from "@/lib/productLine";

import {
  BasePermissionConfig,
  InAppsPermissionConfig,
  PermissionConfig,
  PushMessagesPermissionConfig,
} from "./typedefs";

export function buildPermission(
  config: InAppsPermissionConfig
): InAppsPermission;
export function buildPermission(
  config: PushMessagesPermissionConfig
): PushMessagesPermission;
export function buildPermission(config: BasePermissionConfig): BasePermission;

export function buildPermission(config: PermissionConfig) {
  if (config.productLine === undefined) {
    return `${config.prefix}.${config.permissionType}`;
  } else {
    const { productName, name: productLineName } = config.productLine;
    const shortProductLineName = getShortProductLineName(productLineName);
    return `${config.prefix}.${productName}${shortProductLineName}_${config.permissionType}`;
  }
}

let initialFetchPromise: Promise<void> | null = null;
export const permissions: Ref<Set<string>> = ref(new Set());

export function usePermissions() {
  const tryToFetchPermissions = async () => {
    permissions.value = new Set(await fetchPermissions());
  };

  if (initialFetchPromise === null) {
    initialFetchPromise = tryToFetchPermissions();
  }

  const hasPermission = (permission: Permission) =>
    permissions.value.has(permission);

  const hasBasePermission = (permissionType: BasePermissionType) =>
    hasPermission(buildPermission({ prefix: "base", permissionType }));

  const hasInAppPermission = (
    permissionType: InAppsPermissionType,
    productLine: ProductLine
  ) =>
    hasPermission(
      buildPermission({
        prefix: "inapps",
        permissionType,
        productLine,
      })
    );

  const hasPushMessagePermission = (
    permissionType: PushMessagesPermissionType,
    productLine: ProductLine
  ) =>
    hasPermission(
      buildPermission({
        prefix: "pushnotifications",
        permissionType,
        productLine,
      })
    );

  return {
    // State.
    permissions,
    // Fetching.
    initialFetchPromise,
    tryToFetchPermissions,
    // Permission checkers.
    hasBasePermission,
    hasInAppPermission,
    hasPushMessagePermission,
  };
}
